import Layout from "@/components/Layout";
import { Desc } from "@/components/header/userContent/settings/components";
import { helpLink } from '@/shared/constants/links';
import { breakpoints, mixins } from "@/styles/variables/variables";
import styled from "styled-components";

const NotFound = () => {
  return (
    <Layout logoSvg={"logo-404"}>
      <ErrorWrapper>
        <Desc>
          Такой страницы нет. <br /> Вернитесь на главную или напишите нам,
          мы поможем найти, что вы ищете
        </Desc>
        <a
          href={helpLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Поддержка 
        </a>
      </ErrorWrapper>
    </Layout>
  );
};

export default NotFound;

const ErrorWrapper = styled.div`
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  @media ${breakpoints.desktopXl} {
    gap: 1.876vw;
  }
  @media ${breakpoints.tabletL} {
    gap: 2.346vw;
  }
  @media ${breakpoints.tabletM} {
    gap: 3.23vw;
  }
  @media ${breakpoints.mobile} {
    gap: 7.524vw;
  }

  text-align: center;
  max-width: 300px;
  @media ${breakpoints.desktopXl} {
    max-width: 23.456vw;
  }
  @media ${breakpoints.tabletL} {
    max-width: 29.326vw;
  }
  @media ${breakpoints.tabletM} {
    max-width: 40.377vw;
  }
  @media ${breakpoints.mobile} {
    max-width: 79.937vw;
  }

  & ${Desc} {
    ${mixins.fs14}
  }

  & > a {
    ${mixins.fs10}
    transition: color 0.3s;
    color: var(--wh12);
    &:hover {
      color: var(--wh2);
    }
    &:active {
      color: var(--wh3);
    }
  }
`;
